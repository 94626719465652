@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

html {
  font-size: 13px;
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 15px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC',
    'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-row {
  background-color: #f4fdfe;
}

#Container,
.jrPage,
#ic {
  align-items: center;
  color: aqua;
}
